import "assets/styles/index.scss";
import GlobalLayout from "components/layouts/GlobalLayout";
import { useAuth } from "context/ProtectedRoutes";
import About from "pages/about";
import Contact from "pages/contact";
import Home from "pages/home";
import Login from "pages/login";
import Register from "pages/register";
import Projects from "pages/projects";
import ForgotPassword from "pages/forgotpassword";
import YoutubeCounter from "pages/youtubecounterandclock";
import LightPuckRx from "pages/lightpuckrx";
import LightPuckTx from "pages/lightpucktx";
import CryptoAndStockScroller from "pages/cryptoandstockscroller";
import ResetPassword from "pages/resetpassword";
import { Navigate, Route, Routes, useOutlet } from "react-router-dom";
import UnitConverter from "pages/unitconverter";
import FAQ from "pages/faq";

const ProtectedRoute = () => {
  const { user } = useAuth();
  const outlet = useOutlet();
  if (!user || (user && !user.id)) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return <>{outlet}</>;
};

function App() {
  return (
    <Routes>
      <Route path="/" element={<GlobalLayout />}>
        <Route index element={<Home />} />
        <Route element={<ProtectedRoute />}>
          <Route path="projects" element={<Projects />} />
          <Route path="youtubecounter/:projectID" element={<YoutubeCounter />} />
          <Route path="unitconverter/:projectID" element={<UnitConverter />} />
          <Route path="lightpuckrx/:projectID" element={<LightPuckRx />} />
          <Route path="lightpucktx/:projectID" element={<LightPuckTx />} />
          <Route path="cryptoandstockscroller/:projectID" element={<CryptoAndStockScroller />} />
        </Route>
        <Route path="resetpassword/:token/:id" element={<ResetPassword />} />
        <Route path="about" element={<About />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="forgotpassword" element={<ForgotPassword />} />
        <Route path="faq" element={<FAQ />} />
        <Route element={<ProtectedRoute />}>
          <Route path="contact" element={<Contact />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
