import { Box, Flex, FlexProps, VStack, HStack, Image, Button, Text, Stack, Link, Container } from "@chakra-ui/react";
import Footer from "pages/footer";
import React from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/ProtectedRoutes";
import { useLocalStorage } from "hooks/useLocalStorage";

const GlobalLayout: React.FC<FlexProps> = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const { id } = user ?? {};
  const { removeStore } = useLocalStorage("userInfo");

  return (
    <>
      <Stack p={"2rem"}>
        <Box
          h="8rem"
          // backgroundImage="linear-gradient(to right top, rgba(17, 107, 99, 0.7), rgba(25, 32, 72, 0.7)), url('/img/header.jpg');"
          bg="white"
          backgroundPosition="center center"
          backgroundSize={"cover"}
          w="100%"
          borderBottom="4px"
          borderBottomColor={"#7ACD9D"}
          flex={1}
        >
          <Stack alignItems={"center"} justifyContent={"center"} p="2rem">
            <HStack w="100%" justifyContent={"space-between"}>
              <HStack gap={4} alignItems={"center"}>
                <Link onClick={() => navigate("/")}>
                  <Image w={["50%", "50%", "25%"]} h={["50%", "50%", "25%"]} src="/img/dolibo-logo.png" />
                </Link>
              </HStack>
              {id ? (
                <Stack gap={12} direction={"row-reverse"}>
                  <Button
                    colorScheme="gray"
                    variant="link"
                    size="lg"
                    w="45%"
                    h="4rem"
                    fontSize={["1.5rem", "1.5rem", "2rem"]}
                    onClick={() => {
                      removeStore();
                      logout?.();
                    }}
                  >
                    <Text color={"gray"}>Log out</Text>
                  </Button>
                  <HStack>
                    <Image w="2.5rem" h="3.0rem" src="/img/avatar.png" />
                    <Text fontSize={["1.5rem", "1.5rem", "2rem"]} as="b" color={"black"}>
                      {user?.displayName}
                    </Text>
                  </HStack>
                </Stack>
              ) : (
                <Stack w="40%" spacing={"1rem"} direction={"row-reverse"}>
                  <Button
                    colorScheme="gray"
                    variant="link"
                    size="lg"
                    w="45%"
                    h="4rem"
                    fontSize={["1.5rem", "1.5rem", "2rem"]}
                    onClick={() => navigate("/login")}
                  >
                    <Text color={"gray"}>Log in</Text>
                  </Button>
                  <Button
                    colorScheme="gray"
                    variant="outline"
                    size="lg"
                    w="45%"
                    h="4rem"
                    fontSize={["1.5rem", "1.5rem", "2rem"]}
                    border="2px"
                    borderColor="gray"
                    borderRadius="10px"
                    onClick={() => navigate("/register")}
                  >
                    <Text color={"gray"}>Sign up</Text>
                  </Button>
                </Stack>
              )}
            </HStack>
          </Stack>
        </Box>

        <Box flex={8} minHeight="var(--min-height)">
          <Outlet />
        </Box>
        <Box mt={24}>
          <Footer />
        </Box>
      </Stack>
    </>
  );
};

export default GlobalLayout;
