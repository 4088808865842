import { Box, Flex, HStack, Image, Link, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function ProjectDetailLayout({
  projectName = "",
  projectImgUrl = "",
  children,
}: {
  projectName: string;
  projectImgUrl: string;
  children: React.ReactElement;
}) {
  const navigate = useNavigate();
  return (
    <Box w="100%" minH="var(--min-height)">
      <React.Fragment>
        <VStack alignItems="start" w="100%" spacing="4rem" p="2rem">
          <HStack w="100%" h="100%">
            <Link
              fontSize={["sm", "2xl", "4xl"]}
              as="b"
              onClick={() => {
                navigate("/projects/");
              }}
            >
              Home / Project
            </Link>
            <Text fontSize={["sm", "2xl", "4xl"]} as="b">
              {` / ${projectName}`}
            </Text>
          </HStack>

          <Flex alignItems={"center"} justifyContent={"center"}>
            <Image objectFit={"scale-down"} maxH={"80%"} maxW={"80%"} src={projectImgUrl} alt={projectName} />
          </Flex>
        </VStack>
        {children}
      </React.Fragment>
    </Box>
  );
}

export default ProjectDetailLayout;
