import { VStack } from "@chakra-ui/react";
import AboutContent from "components/home/AboutContent";

const About = () => {

  return (
    <VStack w="100%" spacing={"2rem"} p={8}>
      <AboutContent />
    </VStack>
  );
};

export default About;
