import React from 'react';
import { Box, Flex, HStack, Image, Link, Stack, VStack, Text, SimpleGrid, CheckboxGroup, useDisclosure, Spinner } from "@chakra-ui/react";
import LabelSelect from "components/common/LabelSelect";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "services/api";
import ActionButton from "components/common/ActionButton";
import LabelUnitCategories from "components/common/LabelUnitCategories";
import InputUnitCheckbox from "components/common/InputUnitCheckbox";
import CodeGenerator from "components/home/CodeGenerator";
import CircuitDiagram from "components/home/CircuitDiagram";
import ProjectDetailLayout from "components/layouts/ProjectDetailLayout";
import { RESPONSIVE_STACK_DIRECTION } from "constants/ui";

const UnitConverter = () => {
  const navigate = useNavigate();
  // const { user, updateUser } = useAuth();
  const { projectID } = useParams();
  const [projectName, setProjectName] = useState("");
  const [projectImgUrl, setProjectImgUrl] = useState("");
  const [projectDiagram, setProjectDiagram] = useState("");
  const { isOpen: isCodeGenOpen, onOpen: onCodeGenOpen, onClose: onCodeGenClose } = useDisclosure();
  const [boardList, setBoardList] = useState<string[]>([]);
  const [idLength, setIdLength] = useState<string>("");
  const [idMass, setIdMass] = useState<string>("");
  const [idSpeed, setIdSpeed] = useState<string>("");
  const [idTemperature, setIdTemperature] = useState<string>("");
  const [allValid, setAllValid] = useState(true);
  const { isOpen: isPlanOpen, onOpen: onPlanOpen, onClose: onPlanClose } = useDisclosure();

  const handleOnChangeIdLength = (units: string[]) => {
    units.sort((a, b) => parseInt(a.split("_")[0]) - parseInt(b.split("_")[0]));
    setIdLength(units.map(val => val.split("_")[1]).join(","));
  };

  const handleOnChangeIdMass = (units: string[]) => {
    units.sort((a, b) => parseInt(a.split("_")[0]) - parseInt(b.split("_")[0]));
    setIdMass(units.map(val => val.split("_")[1]).join(","));
  };

  const handleOnChangeIdSpeed = (units: string[]) => {
    units.sort((a, b) => parseInt(a.split("_")[0]) - parseInt(b.split("_")[0]));
    setIdSpeed(units.map(val => val.split("_")[1]).join(","));
  };

  const handleOnChangeIdTemperature = (units: string[]) => {
    units.sort((a, b) => parseInt(a.split("_")[0]) - parseInt(b.split("_")[0]));
    setIdTemperature(units.map(val => val.split("_")[1]).join(","));
  };

  useEffect(() => {
    api.get("/boards", { params: { projectID: projectID } }).then(res => {
      if (res.data && res.data.length > 0) {
        setBoardList(res.data);
      }
    });
    api.get("/getprojectheader", { params: { projectID: projectID } }).then(res => {
      if (res.projectName && res.projectImgUrl) {
        setProjectName(res.projectName);
        setProjectImgUrl(res.projectImgUrl);
        if (res.projectDiagram) {
          setProjectDiagram(res.projectDiagram);
        }
      }
    });
    setIdLength("idKilometer,idMeter,idCentimeter,idMillimeter,idMile,idYard,idFoot,idFootAndInch,idInch,idInchFractional");
    setIdMass("idMetricTon,idKilogram,idGram,idMilligram,idImperialTon,idUSTon,idPound,idOunce");
    setIdSpeed("idMPH,idFPS,idMPS,idKPH,idKnots");
    setIdTemperature("idCelsius,idFahrenheit,idKelvin");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectID]);

  return (
    <ProjectDetailLayout projectName={projectName} projectImgUrl={projectImgUrl}>
      {projectName.length > 0 && projectImgUrl.length > 0 && boardList.length > 0 ? (
        <VStack alignItems="start" w="100%" spacing="4rem" p="2rem">
          {/* Microcontroller */}
          <Stack direction={RESPONSIVE_STACK_DIRECTION} alignItems="start" w="100%" spacing="2rem">
            <Text fontSize="4xl" minW="200px" pt="0.9rem">
              Microcontroller
            </Text>
            <LabelSelect
              options={boardList.map(el => ({
                label: el,
                value: el.replaceAll(" ", "_").toLocaleLowerCase(),
              }))}
              maxHeight="50%"
              w={{ base: "20rem", md: "40rem" }}
            />
          </Stack>

          {/* Unit Categories */}
          <Stack direction={RESPONSIVE_STACK_DIRECTION} alignItems="start" spacing="1rem">
            <Text fontSize="4xl" minW="200px" pt="0.9rem">
              Unit Categories
            </Text>
            <VStack alignItems={"flex-start"}>
              {!allValid && <Text color="red">Please select at least 2 items</Text>}
              <SimpleGrid columns={[1,1,4]} spacing={10}>
                <Box>
                  <LabelUnitCategories text="Length" />
                  <CheckboxGroup
                    defaultValue={[
                      "0_idKilometer",
                      "1_idMeter",
                      "2_idCentimeter",
                      "3_idMillimeter",
                      "4_idMile",
                      "5_idYard",
                      "6_idFoot",
                      "7_idFootAndInch",
                      "8_idInch",
                      "9_idInchFractional",
                    ]}
                    onChange={handleOnChangeIdLength}
                  >
                    <Stack spacing={1}>
                      <InputUnitCheckbox value="0_idKilometer" name="Kilometer" />
                      <InputUnitCheckbox value="1_idMeter" name="Meter" />
                      <InputUnitCheckbox value="2_idCentimeter" name="Centimeter" />
                      <InputUnitCheckbox value="3_idMillimeter" name="Millimeter" />
                      <InputUnitCheckbox value="4_idMile" name="Mile" />
                      <InputUnitCheckbox value="5_idYard" name="Yard" />
                      <InputUnitCheckbox value="6_idFoot" name="Foot" />
                      <InputUnitCheckbox value="7_idFootAndInch" name="Foot and Inch" />
                      <InputUnitCheckbox value="8_idInch" name="Inch" />
                      <InputUnitCheckbox value="9_idInchFractional" name="Inch Fractional" />
                    </Stack>
                  </CheckboxGroup>
                </Box>
                <Box>
                  <LabelUnitCategories text="Mass" />
                  <CheckboxGroup
                    defaultValue={[
                      "0_idMetricTon",
                      "1_idKilogram",
                      "2_idGram",
                      "3_idMilligram",
                      "4_idImperialTon",
                      "5_idUSTon",
                      "6_idPound",
                      "7_idOunce",
                    ]}
                    onChange={handleOnChangeIdMass}
                  >
                    <Stack spacing={1}>
                      <InputUnitCheckbox value="0_idMetricTon" name="Metric Ton" />
                      <InputUnitCheckbox value="1_idKilogram" name="Kilogram" />
                      <InputUnitCheckbox value="2_idGram" name="Gram" />
                      <InputUnitCheckbox value="3_idMilligram" name="Milligram" />
                      <InputUnitCheckbox value="4_idImperialTon" name="Imperial Ton" />
                      <InputUnitCheckbox value="5_idUSTon" name="USTon" />
                      <InputUnitCheckbox value="6_idPound" name="Pound" />
                      <InputUnitCheckbox value="7_idOunce" name="Ounce" />
                    </Stack>
                  </CheckboxGroup>
                </Box>
                <Box>
                  <LabelUnitCategories text="Speed" />
                  <CheckboxGroup defaultValue={["0_idMPH", "1_idFPS", "2_idMPS", "3_idKPH", "4_idKnots"]} onChange={handleOnChangeIdSpeed}>
                    <Stack spacing={1}>
                      <InputUnitCheckbox value="0_idMPH" name="MPH" />
                      <InputUnitCheckbox value="1_idFPS" name="FPS" />
                      <InputUnitCheckbox value="2_idMPS" name="MPS" />
                      <InputUnitCheckbox value="3_idKPH" name="KPH" />
                      <InputUnitCheckbox value="4_idKnots" name="Knots" />
                    </Stack>
                  </CheckboxGroup>
                </Box>
                <Box>
                  <LabelUnitCategories text="Temperature" />
                  <CheckboxGroup defaultValue={["0_idCelsius", "1_idFahrenheit", "2_idKelvin"]} onChange={handleOnChangeIdTemperature}>
                    <Stack spacing={1}>
                      <InputUnitCheckbox value="0_idCelsius" name="Celsius" />
                      <InputUnitCheckbox value="1_idFahrenheit" name="Fahrenheit" />
                      <InputUnitCheckbox value="2_idKelvin" name="Kelvin" />
                    </Stack>
                  </CheckboxGroup>
                </Box>
              </SimpleGrid>
            </VStack>
          </Stack>

          {/* Code! Plans! 3D Enclosure! */}
          <VStack alignItems="start" spacing="6">
            <ActionButton
              text="Code!"
              onClick={() => {
                const isAllInvalid = idLength === "" && idMass === "" && idSpeed === "" && idTemperature === "";
                if (isAllInvalid === false) {
                  const lengthList = idLength.split(",");
                  const massList = idMass.split(",");
                  const speedList = idSpeed.split(",");
                  const temperatureList = idTemperature.split(",");
                  if (lengthList.length < 2) {
                    setIdLength("idKilometer,idMeter");
                  }
                  if (massList.length < 2) {
                    setIdMass("idKilogram,idGram");
                  }
                  if (speedList.length < 2) {
                    setIdSpeed("idMPH,idFPS");
                  }
                  if (temperatureList.length < 2) {
                    setIdTemperature("idCelsius,idFahrenheit");
                  }
                  onCodeGenOpen();
                }
                setAllValid(isAllInvalid ? false : true);
              }}
            />
            <ActionButton
              text="Plan!"
              onClick={() => {
                if (projectDiagram.length > 0) {
                  onPlanOpen();
                }
              }}
            />
            <ActionButton
              text="3D Enclosure!"
              onClick={() => {
                // TODO:
              }}
            />
          </VStack>

          <CodeGenerator
            isOpen={isCodeGenOpen}
            onClose={onCodeGenClose}
            projectID={projectID}
            projectName={projectName}
            params={[idLength, idMass, idSpeed, idTemperature]}
          />
          <CircuitDiagram isOpen={isPlanOpen} onClose={onPlanClose} url={projectDiagram} />
        </VStack>
      ) : (
        <></>
      )}
    </ProjectDetailLayout>
  );
};

export default UnitConverter;
