import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  AspectRatio,
} from "@chakra-ui/react";
import { useEffect} from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import arduino from "react-syntax-highlighter/dist/esm/languages/prism/arduino";

SyntaxHighlighter.registerLanguage("arduino", arduino);

interface Props {
  isOpen: boolean;
  onClose: () => void;
  url: string
}

const CircuitDiagram = ({ isOpen, onClose, url }: Props) => {
  useEffect(() => {
    if (isOpen) {

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w="90%" maxWidth="90%">
        <ModalHeader bg="rgb(16 184 221)">
          <HStack color="white" fontSize={"2rem"} spacing="2rem">
            <Box onClick={onClose} cursor="pointer">
              <AiOutlineCloseCircle size={"3rem"} />
            </Box>
          </HStack>
        </ModalHeader>
        <ModalBody>
          <AspectRatio w="100%" ratio={1} >
              <iframe
                title='Circuit Diagram'
                src={url}
                allowFullScreen
              />
          </AspectRatio>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CircuitDiagram;
