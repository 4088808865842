import { VStack, HStack, Text, Image, Box, Link } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  return (
    <HStack w="100%" minH={"7rem"} justifyContent={"center"} position="relative">
      <Box w="100%" h="100%" bg="rgb(238 238 238)">
        <HStack alignItems={"start"} w="100%" h="100%" spacing={"4rem"} >
          <Image ml={"2rem"} mt={"1rem"} w={"65px"} h={"25px"} src="/img/dolibo-logo.png" />
          <Text pt={"1.5rem"}>© HackMakeMod Inc.</Text>
          <VStack alignItems={"start"} spacing={0} py="1.5rem">
            <Link onClick={() => navigate("/")}>Home</Link>
            <Link onClick={() => navigate("/about")}>About</Link>
            <Link onClick={() => navigate("/contact")}>Contact</Link>
            <Link onClick={() => navigate("/faq")}>FAQ</Link>
          </VStack>
        </HStack>
      </Box>
    </HStack>
  );
}

export default Footer;