import { Box, Button, Center, HStack, Input, Stack, Text, VStack } from "@chakra-ui/react";
import { RESPONSIVE_STACK_DIRECTION } from "constants/ui";

interface Props {
  symbol: string;
  description: string;
}

const TickerSymbolGroup = ({ symbol, description }: Props) => {
  return (
    <Stack direction={RESPONSIVE_STACK_DIRECTION} alignItems="start" spacing="1rem">
      <Text fontSize="4xl" minW="200px" pt="0.6rem">
        Ticker Symbol
      </Text>
      <HStack alignItems="starts">
        <Input
          size="lg"
          borderColor="rgb(98 98 98)"
          borderWidth="2px"
          borderRadius="10px"
          h="4.5rem"
          w={{ base: "18rem", md: "23rem" }}
          fontSize="1.5rem"
          onChange={e => {
            // TODO:
          }}
          value={symbol}
        />
        <Box>
          <Button
            size="lg"
            borderColor="black"
            bg="black"
            borderWidth="2px"
            textColor="cyan.500"
            borderRadius="10px"
            h="4.5rem"
            w={{ base: "10rem", md: "14rem" }}
            ml="2rem"
            fontSize="4xl"
            onClick={() => {
              //TODO
            }}
            isDisabled={false}
            isLoading={false}
          >
            Check it!
          </Button>
        </Box>
      </HStack>
      <Text ml="2rem" fontSize="2xl" as="b">
        {description}
      </Text>
    </Stack>
  );
};

export default TickerSymbolGroup;
