import { Box, Checkbox, HStack, Text } from "@chakra-ui/react"

interface Props {
  value: string
  name:  string
}
const InputUnitCheckbox = ({ value, name }: Props) => {
  return (
    <Box
      h="4.5rem"
      w="30rem"
    >
      <HStack
        w="100%"
        h="100%"
        pr="2rem"
      >
        <Checkbox
          defaultChecked
          size="lg"
          bg="white"
          colorScheme="blackAlpha"
          mt="0.2rem"
          ml="0.8rem"
          className="input-check-box"
          value={value}
        />
        <Text className="no-select-text" fontSize="4xl" mt="1rem">{name}</Text>
      </HStack>
    </Box>
  )
}

export default InputUnitCheckbox;