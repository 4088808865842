import { Box, Flex, HStack, Image, Link, Spinner, Text } from "@chakra-ui/react";
import { CMSPost } from "interfaces/cms";
import CMSApi from "modules/cms/api";
import React, { useEffect, useState } from "react";
import parser from "html-react-parser";

const AboutContent = () => {
  const [about, setAbout] = useState<CMSPost>();

  useEffect(() => {
    (async () => {
      const data = await CMSApi.getAbout();
      if (data.posts.length > 0) {
        setAbout(data.posts[0]);
      }
    })();
  }, []);
  return (
    <Flex
      flexDirection="column"
      boxShadow={"0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)"}
      borderRadius={4}
      w="100%"
    >
      <HStack w="100%" bg="var(--main-bg-color)" p="1rem" justifyContent={"space-between"} borderRadius={4}>
        <HStack>
          <Text fontSize={"2rem"} color="white">
            {about?.title}
          </Text>
        </HStack>
        <Box borderRadius={"50%"} overflow="hidden" minW="4.8rem" w="4.8rem" h="4.8rem">
          <Image w="4.8rem" h="4.8rem" src={about?.feature_image} />
        </Box>
      </HStack>
      <Flex flex="1" p="1rem" flexDirection={"column"}>
        <div className="about-content-container"> {parser(about?.html || "")}</div>
      </Flex>
    </Flex>
  );
};

export default AboutContent;
