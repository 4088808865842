import {
  Flex,
  HStack,
  Text,
  Image,
  VStack,
  Box,
} from "@chakra-ui/react";
import { YouTube } from "interfaces/youtube";

interface Props {
  youtube?: YouTube;
}

const YoutubeProfile = ({ youtube }: Props) => {
  return (
    <Flex
      flexDirection="column"
      borderRadius="1rem"
    >
      <Flex flex="1" mt="-2rem" flexDirection={"column"}>
        {youtube && (
          <HStack spacing={"1.5rem"}>
            <Box
              w="8rem"
              h="8rem"
              border="1px solid transparent"
              overflow={"hidden"}
            >
              <Image
                w="8rem"
                h="8rem"
                src={
                  youtube?.authorThumbnails[1]?.url ??
                  youtube?.authorThumbnails[0]?.url ??
                  ""
                }
              />
            </Box>
            <VStack
              flex={1}
              textAlign={"left"}
              fontSize="1.5rem"
              alignItems="flex-start"
              color="black"
            >
              <Text color="black" as="b">
                {youtube.channel ?? ""}
              </Text>
              <Flex flexDirection={{ base: "column", md: "column" }}>
                <HStack spacing="0.3rem">
                  <Text as="span" fontWeight={"bold"}>
                    {youtube?.subscribers
                      ? Number(youtube?.subscribers).toLocaleString()
                      : 0}
                  </Text>
                  <Text as="span"> Subs</Text>
                </HStack>
                <HStack spacing="0.3rem">
                  <Text as="span" fontWeight={"bold"}>
                    {youtube?.views
                      ? Number(youtube?.views).toLocaleString()
                      : 0}
                  </Text>
                  <Text as="span"> View</Text>
                </HStack>
              </Flex>
            </VStack>
          </HStack>
        )}
      </Flex>
    </Flex>
  );
};

export default YoutubeProfile;
