import { NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper } from "@chakra-ui/react"
interface Props {
  value: string
  onChange: (valueAsString: string, valueAsNumber: number) => void,
}
const InputNumber = ({ value, onChange }: Props) => {
  return (
    <NumberInput
      size="lg"
      step={1}
      defaultValue={1}
      min={1}
      max={100}
      onChange={onChange}
      value={value}
    >
      <NumberInputField
        fontSize="1.6rem"
        h="4.5rem"
        w="9rem"
        boxShadow={"var(--boxShadow)"}
        bgColor="rgb(238 238 238)"
        borderWidth={"2px"}
        borderColor="rgb(89 89 89)"
        borderRadius={"10px"}
      />
      <NumberInputStepper>
        <NumberIncrementStepper style={{border: `0px solid`}}/>
        <NumberDecrementStepper style={{border: `0px solid`}}/>
      </NumberInputStepper>
    </NumberInput>
  )
}

export default InputNumber;