import { Box, Button, Flex, HStack, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text, Link, useToast } from "@chakra-ui/react";
import { ProjectConfiguration } from "mock/project";
// import { BoardInfo } from "interfaces/boards";
// import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import arduino from "react-syntax-highlighter/dist/esm/languages/prism/arduino";
import prism from "react-syntax-highlighter/dist/esm/styles/prism/prism";
import api from "services/api";
import { oldStringFormat } from "utils/function";

SyntaxHighlighter.registerLanguage("arduino", arduino);

interface Props {
  isOpen: boolean;
  onClose: () => void;
  // boardInfo: BoardInfo;
  projectID: string | undefined;
  projectName: string;
  params: string[];
  projectCode?: string;
  projectConfiguration?: ProjectConfiguration[];
}

const CodeGenerator = ({ isOpen, onClose, projectID, projectName, projectCode, projectConfiguration, params }: Props) => {
  const toast = useToast();
  const [templateCode, setTemplateCode] = useState<string>("");

  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(generatedCode || "").then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
          toast({
            title: "Copied!",
            description: "Copied generate code to clipboard!",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
            containerStyle: { fontSize: "1.4rem" },
          });
          onClose();
        },
        function (err) {
          toast({
            title: "Error",
            description: "Unable copy generate code to clipboard!",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
            containerStyle: { fontSize: "1.4rem" },
          });
          onClose();
        },
      );
    } else {
      console.log(`Clipboard API is Not Supported`);
    }
  };

  useEffect(() => {
    if (isOpen) {
      api.get("/templates", { params: { projectID: projectID } }).then(res => {
        setTemplateCode(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const generatedCode = useMemo(() => {
    return oldStringFormat(templateCode, ...params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateCode, params]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w="90%" maxWidth="90%">
        <ModalHeader bg="rgb(16 184 221)">
          <HStack color="white" fontSize={"2rem"} spacing="2rem">
            <Box onClick={onClose} cursor="pointer">
              <AiOutlineCloseCircle size={"3rem"} />
            </Box>
            <HStack justifyContent={"space-between"} w="100%" px="1rem" fontSize={"1.4rem"}>
              <Text color="white" flex={"1"} fontWeight={"bold"} fontSize={"2.4rem"} textTransform="uppercase">
                {projectName}
              </Text>
              <Button bg={"rgb(238 238 238)"} size="lg" onClick={handleCopy} textColor="black" borderWidth={"2px"} borderColor={"rgb(98 98 98)"}>
                COPY TO CLIPBOARD!
              </Button>
            </HStack>
          </HStack>
        </ModalHeader>
        <ModalBody>
          <Flex w="100%" flexDirection={"column"}>
            {
              <Box
                h="40rem"
                overflow={"hidden"}
                overflowY="auto"
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "16px",
                    // borderRadius: '8px',
                    border: `1px solid rgb(176, 176, 176)`,
                    backgroundColor: `rgb(243, 243, 243)`,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: `rgb(183, 183, 183)`,
                  },
                }}
              >
                <SyntaxHighlighter language="arduino" style={prism} wrapLongLines>
                  {generatedCode || ""}
                </SyntaxHighlighter>
              </Box>
            }
            <Text fontSize={"1.6rem"} color="white" bgColor={"black"} p="2rem" as="span" align={"center"}>
              This code uses third party libraries to drive hardware not maintained by us (DoLibo.com). If you have errors when compiling please check
              out our help section
              <Link onClick={() => window.open("/contact", "_blank")}>
                <Text as="u" pl="0.5rem">
                  HERE
                </Text>
              </Link>
            </Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CodeGenerator;
