import { Box, Button, Text, useDisclosure, VStack, HStack, useToast } from "@chakra-ui/react";
import AnimationInput from "components/common/AnimationInput";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import { validateEmail } from "utils/function";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const { isOpen: isLoading, onClose, onOpen } = useDisclosure();
  const { isOpen: isOpenMsg, onOpen: onOpenMsg } = useDisclosure();
  const [userInfo, setUserInfo] = useState<{ email: string; }>({
    email: ""
  });

  const [errMsg, setErrMsg] = useState<string>("");

  const handleRequestResetPw = () => {
    onOpen();
    api
      .post("/requestresetpw", userInfo)
      .then((res) => {
        toast({
          title: "Email sent",
          description: "An email was sent to reset your password. Please check your email",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
          containerStyle: { fontSize: "1.4rem" },
        });
        navigate("/");
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
        setErrMsg(err.message);
        onOpenMsg();
      })
      .finally(() => onClose());
  };

  const handleOnKeyPressEnter = (key: string) => {
    if (
      key === "Enter" &&
      validateEmail(userInfo.email)
    ) {
      handleRequestResetPw();
    }
  };

  const isDisabled = useMemo(() => {
    if (
      !validateEmail(userInfo.email)
    )
      return true;
    return false;
  }, [userInfo]);

  return (
    <HStack w="100%" justifyContent={"center"} minH="var(--min-height)">
      <VStack
        spacing={"1rem"}
        w={{ base: "90%", md: "70%", lg: "50%" }}
        boxShadow={
          "0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)"
        }
        p="1rem"
      >
        <Text w="80%" fontSize={"2rem"} fontWeight="bold">
          Forgot Password
        </Text>

        <AnimationInput
          type={"text"}
          placeholder="Enter Email"
          name={"Email"}
          isEmail
          invalidMsg="Please enter a valid email address."
          onChange={(e) => {
            setUserInfo((prevState) => ({
              ...prevState,
              email: e.target.value,
            }));
          }}
          onKeyPress={(e) => {
            handleOnKeyPressEnter(e.key);
          }}
          invalidMsgStyle={{
            pl: "0",
            fontSize: "1.3rem",
            color: "var(--secondary-color)",
          }}
          boxProps={{ spacing: "0.5rem" }}
        />

        {isOpenMsg && (
          <Text w="80%" fontSize={"1.4rem"} color="var(--secondary-color)">
            {errMsg}
          </Text>
        )}
        <Button
          colorScheme="gray"
          variant='outline'
          size="lg"
          w="80%"
          h="4rem"
          border='2px'
          borderColor='gray'
          borderRadius='10px'
          fontSize={"1.4rem"}
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={handleRequestResetPw}
          onSubmit={handleRequestResetPw}
        >
          Reset Password
        </Button>
        <Button
          colorScheme="gray"
          size="lg"
          w="80%"
          h="4rem"
          border='2px'
          borderColor='gray'
          borderRadius='10px'
          fontSize={"1.4rem"}
          variant="ghost"
          onClick={() => navigate("/login")}
        >
          LOGIN
        </Button>
      </VStack>
    </HStack>
  );
};

export default ForgotPassword;
