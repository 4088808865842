import { Box, HStack, Image, Text } from "@chakra-ui/react"

interface Props {
  text: string,
  onClick: () => void,
}
const ActionButton = ({ text, onClick }: Props) => {
  return (
    <HStack
      spacing="6"
      cursor="pointer"
      onClick={onClick}
    >
      <Box
        w="50px"
        h="50px"
        bgColor="rgb(238 238 238)"
        borderColor="rgb(98 98 98)"
        borderWidth="4px" borderRadius="10px"
        _hover={{ bg: "var(--secondary-bg-color)", }}
      >
        <Image p="1rem" src="/img/dolibo-logo-short.png" />
      </Box>
      <Text
        fontSize="4xl"
        minW="200px"
        pt="0.9rem"
        as='b'
        className="no-select-text"
      >
        {text}
      </Text>
    </HStack>
  )
}

export default ActionButton;