import { Box, Button, Text, useDisclosure, VStack, HStack, Link } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import AnimationInput from "components/common/AnimationInput";
import { useAuth } from "context/ProtectedRoutes";
import { useLocalStorage } from "hooks/useLocalStorage";
import { UserInfo } from "interfaces/user";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import { validateEmail } from "utils/function";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { setValue } = useLocalStorage<UserInfo | undefined>("userInfo");

  const { isOpen: isLoading, onClose, onOpen } = useDisclosure();
  const { isOpen: isOpenMsg, onOpen: onOpenMsg } = useDisclosure();
  const [userInfo, setUserInfo] = useState<{ email: string; password: string }>(
    {
      email: "",
      password: "",
    },
  );

  const handleLogin = () => {
    onOpen();
    api
      .post("/login", userInfo)
      .then((res) => {
        if (res.data) {
          const loggedInUserInfo = { ...res.data, token: res.token };
          login?.(loggedInUserInfo);
          setValue(loggedInUserInfo);
          navigate("/projects");
        } else onOpenMsg();
      })
      .catch(() => {
        onOpenMsg();
      })
      .finally(() => onClose());
  };

  const handleOnKeyPressEnter = (key: string) => {
    if (key === "Enter" && validateEmail(userInfo.email) && userInfo.password) {
      handleLogin();
    }
  };

  const isDisabled = useMemo(() => {
    if (!validateEmail(userInfo.email) || !userInfo.password) return true;
    return false;
  }, [userInfo]);

  return (
    <HStack w="100%" justifyContent={"center"} minH="var(--min-height)">
      <VStack
        spacing={"1rem"}
        w={{ base: "90%", md: "70%", lg: "50%" }}
        boxShadow={
          "0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)"
        }
        p="1rem"
      >
        <HStack w="80%" justifyContent={"space-between"}>
          <Text fontSize={"2rem"} fontWeight="bold"> Login </Text>
          <Link 
            fontSize={"1.3rem"}
            onClick={() => navigate("/forgotpassword")}
          >
            Forgot password
            <ExternalLinkIcon mx='2px' />
          </Link>
        </HStack>
        <AnimationInput
          type={"text"}
          placeholder="Enter Email"
          name="Email"
          isEmail
          invalidMsg="Please enter a valid email address."
          onChange={(e) => {
            setUserInfo((prevState) => ({
              ...prevState,
              email: e.target.value,
            }));
          }}
          onKeyPress={(e) => {
            handleOnKeyPressEnter(e.key);
          }}
          invalidMsgStyle={{
            pl: "0",
            fontSize: "1.3rem",
            color: "var(--secondary-color)",
          }}
          boxProps={{ spacing: "0.5rem" }}
        />
        <AnimationInput
          type={"password"}
          name="Password"
          placeholder="Enter Password"
          onChange={(e) => {
            setUserInfo((prevState) => ({
              ...prevState,
              password: e.target.value,
            }));
          }}
          onKeyPress={(e) => {
            handleOnKeyPressEnter(e.key);
          }}
        />
        {isOpenMsg && (
          <Text w="80%" fontSize={"1.3rem"} color="var(--secondary-color)">
            Email or password incorrect!
          </Text>
        )}

        <Button
          colorScheme="gray" variant='outline'
          size="lg"
          w="80%"
          h="4rem"
          border='2px'
          borderColor='gray'
          borderRadius='10px'
          fontSize={"1.4rem"}
          isLoading={isLoading}
          onClick={handleLogin}
          onSubmit={handleLogin}
          isDisabled={isDisabled}
        >
          LOGIN
        </Button>
        <Button
          colorScheme="gray"
          size="lg"
          w="80%"
          h="4rem"
          border='2px'
          borderColor='gray'
          borderRadius='10px'
          fontSize={"1.4rem"}
          variant="ghost"
          onClick={() => navigate("/register")}
        >
          REGISTER
        </Button>
      </VStack>
    </HStack>
  );
};

export default Login;
