import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Link,
  List,
  ListItem,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import ContactForm from "components/contact/ContactForm";
import { useAuth } from "context/ProtectedRoutes";
import React from "react";

const ContactPage = () => {
  const { user } = useAuth();
  const { displayName } = user ?? {};
  const { isOpen, onOpen, onClose } = useDisclosure();

  return isOpen ? (
    <ContactForm onClose={onClose} />
  ) : (
    <React.Fragment
    >
      <HStack
        w="100%"
        bg="var(--main-bg-color)"
        p="1rem"
        justifyContent={"space-between"}
      >
        <HStack>
          <Text fontSize={"2rem"} color="white">
            Contact and Support
          </Text>
        </HStack>
        <Box
          borderRadius={"50%"}
          overflow="hidden"
          minW="4.8rem"
          w="4.8rem"
          h="4.8rem"
        >
          <Image w="4.8rem" h="4.8rem" src="/img/channels4_profile.jpeg" />
        </Box>
      </HStack>
      <Flex
        flex="1"
        p="1rem"
        flexDirection={"column"}
        overflow="hidden"
        overflowY="auto"
      >
        <Text
          color="var(--main-color)"
          fontSize={"2rem"}
          fontWeight="medium"
          p="1.6rem"
        >
          Before you proceed:
        </Text>
        <Text
          color="rgba(0,0,0,.6)"
          fontSize={"1.35rem"}
          fontWeight="medium"
          p="0 1.6rem"
        >
          Have you installed all the right drivers and boards for your project?
          A more detailed blog post can be found on HackMakeMod's site.
        </Text>
        <Link
          href="https://hackmakemod.com/blogs/projects/diy-kit-guide-building-your-own-subscription-clock"
          textDecoration={"none"}
          h="4rem"
          fontSize={"1.4rem"}
          w="100%"
          textDecor={"none"}
          _hover={{
            textDecor: "none",
          }}
          display="flex"
          justifyContent={"center"}
          alignItems="center"
          bg="var(--chakra-colors-teal-500)"
          mt="1.6rem"
          color="white"
          fontWeight={"medium"}
          borderRadius="0.5rem"
          userSelect={"none"}
        >
          GO TO THE BLOG POST
        </Link>
        <Text
          color="rgba(0,0,0,.6)"
          fontSize={"2rem"}
          fontWeight="medium"
          p="1.6rem"
        >
          Boards:
        </Text>
        <Text
          color="var(--main-color)"
          fontSize={"1.35rem"}
          fontWeight="medium"
          p="0 1.6rem"
        >
          <Link
            href="https://arduino.esp8266.com/stable/package_esp8266com_index.json"
            isExternal
          >
            https://arduino.esp8266.com/stable/package_esp8266com_index.json
          </Link>
        </Text>
        <Text
          color="rgba(0,0,0,.6)"
          fontSize={"2rem"}
          fontWeight="medium"
          p="1.6rem"
        >
          Drivers:
        </Text>
        <Text
          color="var(--main-color)"
          fontSize={"1.35rem"}
          fontWeight="medium"
          p="0 1.6rem"
        >
          <Link
            href="https://github.com/nodemcu/nodemcu-devkit/tree/master/Drivers"
            isExternal
          >
            https://github.com/nodemcu/nodemcu-devkit/tree/master/Drivers
          </Link>
        </Text>
        <Text
          color="rgba(0,0,0,.6)"
          fontSize={"2rem"}
          fontWeight="medium"
          p="1.6rem"
        >
          Libraries:
        </Text>
        <UnorderedList
          color="rgba(0,0,0,.6)"
          fontSize={"1.35rem"}
          fontWeight="medium"
          p="0 1.6rem"
        >
          <ListItem>ArduinoJson.h</ListItem>
          <ListItem>MD_MAX72xx.h</ListItem>
          <ListItem>MD_Parola.h</ListItem>
          <ListItem>NTPClient.h</ListItem>
          <ListItem>WiFiUdp.h</ListItem>
        </UnorderedList>
        <Text
          color="rgba(0,0,0,.6)"
          fontSize={"2rem"}
          fontWeight="medium"
          p="1.6rem"
        >
          Your project setup:
        </Text>
        <HStack p="0 1.6rem">
          <Box p={5} shadow="md" borderWidth="1px" width="100%">
            <Text
              color="var(--main-color)"
              fontSize={"1.5rem"}
              fontWeight="medium"
            >
              Project Name: {displayName}
            </Text>
            <Text
              color="rgba(0,0,0,.6)"
              fontSize={"1.35rem"}
              fontWeight="medium"
              pt="1rem"
            >
              D1 mini ESP8266
            </Text>
            <Text
              color="rgba(0,0,0,.6)"
              fontSize={"1.35rem"}
              fontWeight="medium"
              pt="1rem"
            >
              Pins:
            </Text>
            <List>
              <ListItem
                color="rgba(0,0,0,.6)"
                fontSize={"1.35rem"}
                p="0 1.6rem"
              >
                Digital Data: D7
              </ListItem>
              <ListItem
                color="rgba(0,0,0,.6)"
                fontSize={"1.35rem"}
                p="0 1.6rem"
              >
                Clock: D5
              </ListItem>
              <ListItem
                color="rgba(0,0,0,.6)"
                fontSize={"1.35rem"}
                p="0 1.6rem"
              >
                CS: D8
              </ListItem>
            </List>
            <Text
              color="rgba(0,0,0,.6)"
              fontSize={"1.35rem"}
              fontWeight="medium"
              pt="1rem"
            >
              Also! make sure you double check the spelling of your Wifi SSID
              and password in the code. Your project may not be on your network!
            </Text>
          </Box>
        </HStack>
        <Button
          colorScheme="teal"
          size="lg"
          w="100%"
          h="4rem"
          fontSize={"1.4rem"}
          mt="1.6rem"
          onClick={onOpen}
        >
          SEND US AN EMAIL
        </Button>
      </Flex>
    </React.Fragment>
  );
};

export default ContactPage;
