import { Box, Checkbox, CheckboxProps, HStack, Text } from "@chakra-ui/react";
import React from "react";

interface Props {
  text: string;
  width?: string;
  value?: string;
  handleOnCheckboxChange: (value: string | undefined, checked: boolean) => void;
}
const InputCheckbox = ({ text, handleOnCheckboxChange, value }: Props & CheckboxProps) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleOnCheckboxChange(value, e.target.checked);
  };
  return (
    <Box borderColor="rgb(98 98 98)" borderWidth="2px" bg="rgb(238 238 238)" borderRadius="10px" h="4.5rem">
      <HStack w="100%" h="100%" justifyContent="space-between" pr="2rem">
        <Checkbox
          defaultChecked
          size="lg"
          bg="white"
          colorScheme="blackAlpha"
          mt="0.2rem"
          ml="0.8rem"
          className="input-check-box"
          onChange={handleOnChange}
        />
        <Text className="no-select-text" fontSize="4xl" mt="1rem">
          {text}
        </Text>
      </HStack>
    </Box>
  );
};

export default InputCheckbox;
