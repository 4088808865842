import { Box, Flex, HStack, Image, Link, Stack, VStack, Text, useDisclosure, Spinner } from "@chakra-ui/react";
import LabelSelect from "components/common/LabelSelect";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "services/api";
import ActionButton from "components/common/ActionButton";
import CodeGenerator from "components/home/CodeGenerator";
import CircuitDiagram from "components/home/CircuitDiagram";
import InputNumber from "components/common/InputNumber";
import TickerSymbolGroup from "components/common/TickerSymbolGroup.tsx";
import InputCheckbox from "components/common/InputCheckbox";
import moment from "moment";
import ProjectDetailLayout from "components/layouts/ProjectDetailLayout";
import { RESPONSIVE_STACK_DIRECTION } from "constants/ui";

const CryptoAndStockScroller = () => {
  // const { user, updateUser } = useAuth();
  const { projectID } = useParams();
  const [projectName, setProjectName] = useState("");
  const [projectImgUrl, setProjectImgUrl] = useState("");
  const [projectDiagram, setProjectDiagram] = useState("");
  const [boardList, setBoardList] = useState<string[]>([]);
  const [selectedBoard, setSelectedBoard] = useState("");
  const [matrixModule, setMatrixModule] = useState("1");
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [selectedScrollSpeed, setSelectedScrollSpeed] = useState("");

  const timeZones = moment.tz.names().map(el => ({ label: el, value: el }));
  const { isOpen: isCodeGenOpen, onOpen: onCodeGenOpen, onClose: onCodeGenClose } = useDisclosure();
  const { isOpen: isPlanOpen, onOpen: onPlanOpen, onClose: onPlanClose } = useDisclosure();

  useEffect(() => {
    document.getElementById("container-body")?.scrollTo({ top: 0 });
    api.get("/getprojectheader", { params: { projectID: projectID } }).then(res => {
      if (res.projectName && res.projectImgUrl) {
        setProjectName(res.projectName);
        setProjectImgUrl(res.projectImgUrl);
        if (res.projectDiagram) {
          setProjectDiagram(res.projectDiagram);
        }
      }
    });
    api.get("/boards", { params: { projectID: projectID } }).then(res => {
      if (res.data && res.data.length > 0) {
        setBoardList(res.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectID]);

  return (
    <ProjectDetailLayout projectName={projectName} projectImgUrl={projectImgUrl}>
      {projectName.length > 0 && projectImgUrl.length > 0 && boardList.length > 0 ? (
        <Stack gap={10} alignItems="start">
          {/* Microcontroller */}
          <Stack direction={RESPONSIVE_STACK_DIRECTION} alignItems="start" w="100%" spacing="2rem">
            <Text fontSize="4xl" minW="200px" pt="0.9rem">
              Microcontroller
            </Text>
            <LabelSelect
              options={boardList.map(el => ({
                label: el,
                value: el.replaceAll(" ", "_").toLocaleLowerCase(),
              }))}
              maxHeight="50%"
              w={{ base: "20rem", md: "40rem" }}
              value={selectedBoard}
              onChange={e => {
                setSelectedBoard(e.target.value);
              }}
            />
          </Stack>

          {/* Display */}
          <Stack direction={RESPONSIVE_STACK_DIRECTION} alignItems="start" spacing="1rem">
            <Text fontSize="4xl" minW="200px" pt="0.6rem">
              Display
            </Text>
            <HStack spacing={"3rem"}>
              <Text fontSize="3xl" pt="0.4rem">
                MAX7219 Dot Matrix Module
              </Text>
              <Text fontSize="3xl" pt="0.4rem">
                X
              </Text>
              <InputNumber
                value={matrixModule}
                onChange={(s, n) => {
                  setMatrixModule(s);
                }}
              />
            </HStack>
          </Stack>

          {/* TickerSymbol */}
          <TickerSymbolGroup symbol="APPL" description="Apple Inc. (AAPL) 150.52" />
          <TickerSymbolGroup symbol="MSFT" description="Microsoft Corporation (MSFT) 241.98" />
          <TickerSymbolGroup symbol="" description="-" />
          <TickerSymbolGroup symbol="" description="-" />
          <TickerSymbolGroup symbol="" description="-" />

          {/* Display Modules */}
          <Stack direction={RESPONSIVE_STACK_DIRECTION} alignItems="start" spacing="1rem">
            <Text fontSize="4xl" minW="200px" pt="0.9rem">
              Display Modules
            </Text>
            <HStack spacing={"2rem"}>
              <InputCheckbox
                text="Time"
                width="12rem"
                handleOnCheckboxChange={e => {
                  // setDispTime(e.target.checked.toString())
                }}
              />
              <InputCheckbox
                text="Date"
                width="12rem"
                handleOnCheckboxChange={e => {
                  // setDispDate(e.target.checked.toString())
                }}
              />
              <InputCheckbox
                text="Day"
                width="12rem"
                handleOnCheckboxChange={e => {
                  // setDispDay(e.target.checked.toString())
                }}
              />
            </HStack>
          </Stack>

          {/* Timezone */}
          <Stack direction={RESPONSIVE_STACK_DIRECTION} alignItems="start" spacing="1rem">
            <Text fontSize="4xl" minW="200px" pt="0.9rem">
              Timezone
            </Text>
            <LabelSelect
              options={timeZones}
              onChange={e => {
                setSelectedTimezone(e.target.value);
              }}
              value={selectedTimezone}
              maxHeight="50%"
              placeholder="Select Timezone"
              w={{ base: "20rem", md: "40rem" }}
            />
          </Stack>

          {/* Scroll Speed */}
          <Stack direction={RESPONSIVE_STACK_DIRECTION} alignItems="start" spacing="1rem">
            <Text fontSize="4xl" minW="200px" pt="0.9rem">
              Scroll Speed
            </Text>
            <LabelSelect
              options={[
                { label: "Slow", value: "slow" },
                { label: "Medium", value: "medium" },
                { label: "Fast", value: "fast " },
              ]}
              onChange={e => {
                setSelectedScrollSpeed(e.target.value);
              }}
              value={selectedScrollSpeed}
              maxHeight="50%"
              placeholder="Select Scroll Speed"
              w={{ base: "20rem", md: "40rem" }}
            />
          </Stack>

          {/* Code! Plans! 3D Enclosure! */}
          <VStack alignItems="start" spacing="6">
            <ActionButton
              text="Code!"
              onClick={() => {
                onCodeGenOpen();
              }}
            />
            <ActionButton
              text="Plan!"
              onClick={() => {
                if (projectDiagram.length > 0) {
                  onPlanOpen();
                }
              }}
            />
            <ActionButton
              text="3D Enclosure!"
              onClick={() => {
                // TODO:
              }}
            />
          </VStack>

          <CodeGenerator
            isOpen={isCodeGenOpen}
            onClose={onCodeGenClose}
            projectID={projectID}
            projectName={projectName}
            params={
              [
                // TODO:
              ]
            }
          />
          <CircuitDiagram isOpen={isPlanOpen} onClose={onPlanClose} url={projectDiagram} />
        </Stack>
      ) : (
        <></>
      )}
    </ProjectDetailLayout>
  );
};

export default CryptoAndStockScroller;
