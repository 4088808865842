import { Button, Text, useDisclosure, VStack, HStack } from "@chakra-ui/react";
import AnimationInput from "components/common/AnimationInput";
import { useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "services/api";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token, id } = useParams();
  const { isOpen: isLoading, onClose, onOpen } = useDisclosure();
  const { isOpen: isOpenMsg, onOpen: onOpenMsg } = useDisclosure();

  const [newPassword, setNewPassword] = useState<string>("");

  const handleResetPassword = () => {
    onOpen();
    const data = {
      token: token,
      id: id,
      newPassword: newPassword
    }
    api
      .post("/resetpassword", data)
      .then((res) => {
        if (res) {
          navigate("/login")
        } else onOpenMsg();
      })
      .catch(() => {
        onOpenMsg();
      })
      .finally(() => onClose());
  };

  const handleOnKeyPressEnter = (key: string) => {
    if (key === "Enter" && newPassword.length >= 4) {
      handleResetPassword();
    }
  };

  const isDisabled = useMemo(() => {
    if (newPassword.length >= 4) return false;
    return true;
  }, [newPassword]);

  return (
    <HStack w="100%" justifyContent={"center"} minH="var(--min-height)">
      <VStack
        spacing={"1rem"}
        w={{ base: "90%", md: "70%", lg: "50%" }}
        boxShadow={
          "0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)"
        }
        p="1rem"
      >
        <HStack w="80%" justifyContent={"space-between"}>
          <Text fontSize={"2rem"} fontWeight="bold"> Create new password </Text>
        </HStack>
        <AnimationInput
          type={"password"}
          placeholder="New password"
          name="Password"
          invalidMsg="Please enter a valid password."
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
          onKeyPress={(e) => {
            handleOnKeyPressEnter(e.key);
          }}
          invalidMsgStyle={{
            pl: "0",
            fontSize: "1.3rem",
            color: "var(--secondary-color)",
          }}
          boxProps={{ spacing: "0.5rem" }}
        />
        {isOpenMsg && (
          <Text w="80%" fontSize={"1.3rem"} color="var(--secondary-color)">
            Email or password incorrect!
          </Text>
        )}

        <Button
          colorScheme="gray" variant='outline'
          size="lg"
          w="80%"
          h="4rem"
          border='2px'
          borderColor='gray'
          borderRadius='10px'
          fontSize={"1.4rem"}
          isLoading={isLoading}
          onClick={handleResetPassword}
          onSubmit={handleResetPassword}
          isDisabled={isDisabled}
        >
          Create new password
        </Button>
      </VStack>
    </HStack>
  );
};

export default ResetPassword;
