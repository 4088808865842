import { Box } from "@chakra-ui/react";
import ContactContent from "components/contact/Contact";

const Contact = () => {
  return (
    <Box p={12} >
      <ContactContent />;
    </Box>
  )
};

export default Contact;
