import { Box, Button, HStack, Text, useDisclosure, VStack } from "@chakra-ui/react";
import AnimationInput from "components/common/AnimationInput";
import { useAuth } from "context/ProtectedRoutes";
import { useLocalStorage } from "hooks/useLocalStorage";
import { UserInfo } from "interfaces/user";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import { validateEmail } from "utils/function";

const Register = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { setValue } = useLocalStorage<UserInfo | undefined>("userInfo");

  const { isOpen: isLoading, onClose, onOpen } = useDisclosure();
  const { isOpen: isOpenMsg, onOpen: onOpenMsg } = useDisclosure();
  const [userInfo, setUserInfo] = useState<{
    email: string;
    password: string;
    displayName: string;
  }>({
    email: "",
    password: "",
    displayName: "",
  });

  const [errMsg, setErrMsg] = useState<string>("");

  const handleRegister = () => {
    onOpen();
    api
      .post("/signup", userInfo)
      .then((res) => {
        if (res.data) {
          const signedUpUserInfo = { ...res.data, token: res.token };
          login?.(signedUpUserInfo);
          setValue(signedUpUserInfo);
          navigate("/");
        }
      })
      .catch((err) => {
        // console.log(JSON.stringify(err));
        setErrMsg(err.message);
        onOpenMsg();
      })
      .finally(() => onClose());
  };

  const handleOnKeyPressEnter = (key: string) => {
    if (
      key === "Enter" &&
      userInfo.displayName &&
      validateEmail(userInfo.email) &&
      userInfo.password
    ) {
      handleRegister();
    }
  };

  const isDisabled = useMemo(() => {
    if (
      !validateEmail(userInfo.email) ||
      !userInfo.password ||
      !userInfo.displayName
    )
      return true;
    return false;
  }, [userInfo]);

  return (
    <HStack w="100%" justifyContent={"center"} minH="var(--min-height)">
      <VStack
        spacing={"1rem"}
        w={{ base: "90%", md: "70%", lg: "50%" }}
        boxShadow={
          "0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)"
        }
        p="1rem"
      >
        <Text w="80%" fontSize={"2rem"} fontWeight="bold">
          Register
        </Text>

        <AnimationInput
          type={"text"}
          placeholder="Enter a username"
          name={"Display Name"}
          value={userInfo?.displayName}
          onChange={(e) => {
            setUserInfo((prevState) => ({
              ...prevState,
              displayName: (e.target.value ?? "").replace(/[^a-zA-Z]/g, ""),
            }));
          }}
          onKeyPress={(e) => {
            handleOnKeyPressEnter(e.key);
          }}
          msgOnFocus="No spaces or special characters"
          boxProps={{ spacing: "0.5rem" }}
          onKeyDown={(e) => {
            if (/[^a-zA-Z]/.test(e.key)) {
              return true;
            }
            return false;
          }}
        />

        <AnimationInput
          type={"text"}
          placeholder="Enter Email"
          name={"Email"}
          isEmail
          invalidMsg="Please enter a valid email address."
          onChange={(e) => {
            setUserInfo((prevState) => ({
              ...prevState,
              email: e.target.value,
            }));
          }}
          onKeyPress={(e) => {
            handleOnKeyPressEnter(e.key);
          }}
          invalidMsgStyle={{
            pl: "0",
            fontSize: "1.3rem",
            color: "var(--secondary-color)",
          }}
          boxProps={{ spacing: "0.5rem" }}
        />

        <AnimationInput
          type={"password"}
          placeholder="Enter Password"
          name={"Password"}
          onChange={(e) => {
            setUserInfo((prevState) => ({
              ...prevState,
              password: e.target.value,
            }));
          }}
          onKeyPress={(e) => {
            handleOnKeyPressEnter(e.key);
          }}
        />
        {isOpenMsg && (
          <Text w="80%" fontSize={"1.4rem"} color="var(--secondary-color)">
            {errMsg}
          </Text>
        )}
        <Button
          colorScheme="gray"
          variant='outline'
          size="lg"
          w="80%"
          h="4rem"
          border='2px'
          borderColor='gray'
          borderRadius='10px'
          fontSize={"1.4rem"}
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={handleRegister}
          onSubmit={handleRegister}
        >
          REGISTER
        </Button>
        <Button
          colorScheme="gray" 
          size="lg"
          w="80%"
          h="4rem"
          border='2px'
          borderColor='gray'
          borderRadius='10px'
          fontSize={"1.4rem"}
          variant="ghost"
          onClick={() => navigate("/login")}
        >
          LOGIN
        </Button>
      </VStack>
    </HStack>
  );
};

export default Register;
