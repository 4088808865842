import axios from "axios";
import { AXIOS_BASE_URL, AXIOS_REQUEST_TIME_OUT, AXIOS_TIMEOUT_ERROR_MESSAGE } from "constants/api";

const baseAxios = axios.create({
    baseURL: AXIOS_BASE_URL,
    timeout: AXIOS_REQUEST_TIME_OUT,
    timeoutErrorMessage: AXIOS_TIMEOUT_ERROR_MESSAGE,
    withCredentials: true,
});

export default baseAxios;
