import {
  Select,
  SelectProps,
  VStack,
  Text,
  StackProps,
} from "@chakra-ui/react";

interface Props {
  options: {
    label: string;
    value: string | number;
  }[];
  description?: string;
  stackProps?: StackProps;
}

const LabelSelect = ({
  options,
  description,
  stackProps,
  ...rest
}: Props & SelectProps) => {
  return (
    <VStack w="100%" alignItems={"flex-start"} py="0.5rem" {...stackProps}>
      <Select
        size={"lg"}
        fontSize="1.6rem"
        h="4.5rem"
        boxShadow={"var(--boxShadow)"}
        bgColor="rgb(238 238 238)"
        borderWidth={"2px"}
        borderColor="rgb(89 89 89)"
        borderRadius={"10px"}
        {...rest}
      >
        {options.map((el, index) => (
          <option key={String(index)} value={el.value}>
            {el.label}
          </option>
        ))}
      </Select>
      {description && (
        <Text fontSize={"1.2rem"} pl="1rem" color="var(--secondary-color)">
          {description}
        </Text>
      )}
    </VStack>
  );
};

export default LabelSelect;
