export const AXIOS_BASE_URL = process.env.REACT_APP_AXIOS_BASE_URL || "";
export const AXIOS_REQUEST_TIME_OUT = 45000;

export const AXIOS_TIMEOUT_CODE = "ECONNABORTED";
export const AXIOS_TIMEOUT_ERROR_MESSAGE = "AXIOS_TIMEOUT_ERROR_MESSAGE";

export const API_RESULT_NORMAL = "0";

export const API_APP_ERROR_CODE = Object.freeze({
    CANCEL: "CANCEL",
});
export const API_RESULT_CODE = {
    OK: "OK",
    ERROR: "ERROR",
};

export const REACT_APP_BASE_CMS_URL = process.env.REACT_APP_BASE_CMS_URL || "";
export const REACT_APP_CMS_CONTENT_API_KEY = process.env.REACT_APP_CMS_CONTENT_API_KEY || "";


// AUTH
export const AUTH_LOGIN = "/signup";
export const AUTH_SIGNUP = "/login";

