import { Box, Text } from "@chakra-ui/react"

interface Props {
  text: string,
}
const LabelUnitCategories = ({ text }: Props) => {
  return (
  <Box
    bgColor="rgb(238 238 238)"
    borderWidth={"2px"}
    borderColor="rgb(89 89 89)"
    borderRadius="10px"
    w="25rem"
    h="4.5rem"
    pl="2.5rem"
    className="no-select-text"
  >
    <Text fontSize="4xl" align="left" pt="0.4rem">{text}</Text>
  </Box>
  )
}

export default LabelUnitCategories;
