import {
  Flex,
  HStack,
  StackProps,
  Text,
  Textarea,
  TextareaProps,
  VStack,
} from "@chakra-ui/react";

const AnimationArea = ({
  name,
  boxProps,
  ...rest
}: {
  name?: string;
  boxProps?: StackProps;
} & TextareaProps) => {
  return (
    <VStack w="80%" spacing={"1.5rem"} alignItems={"flex-start"} {...boxProps}>
      <HStack alignItems={"flex-end"} w="100%">
        <Flex className="animation-area" flex={1}>
          <Textarea size="lg" required fontSize={"1.6rem"} {...rest} />
          <Text as="label" className="label-name">
            <Text as="span" className="content-name">
              {name}
            </Text>
          </Text>
        </Flex>
      </HStack>
    </VStack>
  );
};

export default AnimationArea;
