/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosRequestConfig, HeadersDefaults } from "axios";
import {
  API_APP_ERROR_CODE,
  AXIOS_BASE_URL,
  AXIOS_REQUEST_TIME_OUT,
  AXIOS_TIMEOUT_CODE,
} from "constants/api";
import { BaseResponse } from "interfaces/response";
import { UserInfo } from "interfaces/user";
import baseAxios from "services/baseAxios";

const errorHandle = async (url: string, error: AxiosError) => {
  const errorRes = {
    apiPath: url,
    errorCode: error.response?.status,
    message: error.message,
  } as BaseResponse;
  const config: AxiosRequestConfig = error?.config;
  const res = error?.response as any;
  const statusCode = res?.status;
  if (res?.data?.message) errorRes.message = res?.data?.message;
  if (statusCode) {
    switch (statusCode) {
      //Request Timeout
      case 400: {
        // TODO:

        return errorRes;
      }

      case 401: {
        // TODO: redirect to Login page
        localStorage.removeItem("userInfo");
        window.location.href = "/login";
        return errorRes;
      }
      // Request Timeout
      case 408: {
        // TODO:

        return request(url, config);
      }
      // Internal Server Error
      case 500: {
        // TODO: <TBD>

        return errorRes;
      }
      // Server Maintenance
      case 503: {
        // TODO: redirect to Maintenance page

        return errorRes;
      }
    }

    return errorRes;
  }

  const newConfig: AxiosRequestConfig = error?.config;
  if (newConfig) {
    switch (error.code) {
      case AXIOS_TIMEOUT_CODE:
        // TODO: <TBD>

        return request(url, newConfig);
    }
  }
  if (error?.isAxiosError) {
    // Network error
    // TODO: <TBD>
    return errorRes;
  }

  return errorRes;
};

const request = async <T = any>(
  url: string,
  config: AxiosRequestConfig,
): Promise<T & BaseResponse> => {
  let result = null;
  try {
    config.baseURL = AXIOS_BASE_URL;
    config.timeout = AXIOS_REQUEST_TIME_OUT;

    const storedValues = localStorage.getItem("userInfo");
    const userInfo = storedValues ? JSON.parse(storedValues) as UserInfo : undefined;
    if (userInfo?.token) {
      baseAxios.defaults.headers = {
        ...baseAxios.defaults.headers,
        Authorization: `Bearer ${userInfo.token}`
      } as HeadersDefaults;
    } else {
      baseAxios.defaults.headers = {
        ...baseAxios.defaults.headers,
      } as HeadersDefaults;
    }

    const res = await baseAxios(config);
    result = res.data;

    return result;
  } catch (error: any) {
    // CancelToken
    if (axios.isCancel(error)) {
      result = {
        apiPath: url,
        errorCode: API_APP_ERROR_CODE.CANCEL,
      } as BaseResponse;
    } else {
      result = await errorHandle(url, error);
    }
    return Promise.reject(result);
  }
};

const api = {
  post: <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => {
    return request<T>(url, { method: "post", url, data, ...config });
  },
  get: <T = any>(url: string, config?: AxiosRequestConfig) => {
    return request<T>(url, { method: "get", url, ...config });
  },
  put: <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => {
    return request<T>(url, { method: "put", url, data, ...config });
  },
};

export default api;
