import { Box, Button, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import AnimationArea from "components/common/AnimationArea";
import LabelSelect from "components/common/LabelSelect";
import { useAuth } from "context/ProtectedRoutes";
import { BoardInfo } from "interfaces/boards";
import { useEffect, useState } from "react";
import { MdEmail } from "react-icons/md";
import api from "services/api";

interface Props {
  onClose: () => void;
}

const ContactForm = ({ onClose }: Props) => {
  const { user } = useAuth();
  const [boardList, setBoards] = useState<BoardInfo[]>([]);
  const [contactInfo, setContactInfo] = useState<{
    error: string;
    message: string;
    board: BoardInfo;
  }>({
    error: "",
    message: "",
    board: {
      clkPin: "",
      csPin: "",
      dataPin: "",
      id: 0,
      name: "",
      transitionDuration: 0,
      ssid: "",
      password: "",
      user: "",
      projectName: "",
      timezone: "",
    },
  });

  const handleSendEmail = () => {
    const data = {
      from: user?.email,
      board: contactInfo.board.name,
      error: contactInfo.error,
      message: contactInfo.message,
    }
    api.post("/email", data).finally(() => {
      onClose();
    });
  }

  useEffect(() => {
    api.get("/boards").then((res) => {
      if (res.data && res.data.length > 0) {
        setBoards(res.data);
        setContactInfo((prevState) => ({
          ...prevState,
          board: res.data[0],
        }));
      }
    });
  }, []);

  return (
    <Flex w="100%" flexDirection={"column"}>
      <VStack alignSelf={"flex-start"} alignItems="flex-start" mb="2.5rem">
        <Text fontSize={"2.4rem"}>Support Form</Text>
        <Text fontSize={"1.4rem"} color="var(--secondary-color)">
          We try to respond within 24 hours :)
        </Text>
      </VStack>

      <VStack w="100%" spacing={"1.5rem"} alignItems={"flex-start"} mb="2.5rem">
        <HStack alignItems={"flex-end"} w="100%">
          <Box w="3rem" color={"green"}>
            <MdEmail size={"2rem"} />
          </Box>

          <VStack
            alignItems={"flex-start"}
            borderBottom={"1px dashed var(--secondary-color)"}
            w="100%"
          >
            <Text fontSize={"1.2rem"} color="var(--secondary-color)">
              Your email address
            </Text>
            <Text fontSize={"1.6rem"} color="var(--secondary-color)">
              {user?.email}
            </Text>
          </VStack>
        </HStack>
      </VStack>

      <VStack alignItems={"flex-start"} mb="2.5rem">
        <LabelSelect
          options={boardList.map((el) => ({
            label: el.name,
            value: el.id,
          }))}
          maxHeight={"50%"}
          onChange={(e) => {
            const id = Number(e.target.value);
            const board = boardList.find((el) => el.id === id);
            if (board) {
              setContactInfo((prevState) => ({
                ...prevState,
                board: board,
              }));
            }
          }}
          stackProps={{
            pb: 0,
          }}
        />
        <Text fontSize={"1.2rem"} color="var(--secondary-color)" pl="1rem">
          We got this from your active project. Is this the board you're using?
        </Text>
      </VStack>

      <Flex flexDirection={"column"}>
        <AnimationArea
          placeholder="This error is ennoying! {paste here}"
          name="I'm getting this error when I compile:"
          rows={5}
          boxProps={{ spacing: "0.5rem", w: "100%" }}
          mb="2.5rem"
          defaultValue={contactInfo.error}
          onChange={(e) => {
            setContactInfo((prevState) => ({
              ...prevState,
              error: e.target.value,
            }));
          }}
        />
        <Text
          fontSize={"1.2rem"}
          textAlign="right"
          color="var(--secondary-color)"
        >
          14
        </Text>
      </Flex>

      <Flex flexDirection={"column"}>
        <AnimationArea
          placeholder="I just want to tell you, you're awesome!"
          name="Message"
          rows={5}
          boxProps={{ spacing: "0.5rem", w: "100%" }}
          mb="2.5rem"
          defaultValue={contactInfo.message}
          onChange={(e) => {
            setContactInfo((prevState) => ({
              ...prevState,
              message: e.target.value,
            }));
          }}
        />
        <Text
          fontSize={"1.2rem"}
          textAlign="right"
          color="var(--secondary-color)"
        >
          6
        </Text>
      </Flex>
      <HStack justifyContent={"space-between"}>
        <Button
          colorScheme="teal"
          variant="link"
          p="1.5rem 1rem"
          fontSize={"1.4rem"}
          _hover={{}}
          onClick={onClose}
        >
          CANCEL
        </Button>
        <Button
          colorScheme="teal"
          variant="solid"
          p="1.5rem 1rem"
          fontSize={"1.4rem"}
          onClick={handleSendEmail}
        >
          SEND
        </Button>
      </HStack>
    </Flex>
  );
};

export default ContactForm;
