import { ProjectConfiguration } from "mock/project";

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const oldStringFormat = (str: string, ...args: string[]) =>
  str.replace(/{{(\d+)}}/g, (_match, index) => args[index] || "");

export const isYoutubeChannelUrl = (url: string): boolean => {
  return url.includes("youtube.com/c/") || 
  url.includes("youtube.com/channel/") ||
  url.includes("youtube.com/user/") ||
  url.includes("youtube.com/@")
}


export const stringFormat = (str: string, config: ProjectConfiguration[]) => {
  return str.replace(/{{(.*?)}}/g, (_match, key) => {
    const found = config.find(item => item.key === key) 
    if (found) {
      if (Array.isArray(found.value)) {
        return `[${found.value.join(', ')}]`
      }
      return found.value.toString()
    }
    return ''
  })
}