import { VStack, Stack, Text, Image, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/ProtectedRoutes";
import { useEffect } from "react";

const Home = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const { id } = user ?? {};
    if (id) {
      navigate("/projects");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

//minH="var(--min-height)"
  return (
    <VStack justifyContent={"center"} p="2rem" minH="var(--min-height)">
      <Stack direction={{base: "column", md: "row"}} w="100%" justifyContent={"center"} spacing='4rem'>
        <VStack>
          <Text w="100%" fontSize={{ base: "7xl", md: "8xl"}} as='b'>Microprocessor</Text>
          <Text w="100%" fontSize={{ base: "7xl", md: "8xl"}} as='b'>code generator</Text>
        </VStack>
        <Image src="/img/ESP8266D1Mini.png" />
      </Stack>
      <Button
          colorScheme="cyan"
          size="lg"
          w="25%"
          maxW={"200px"}
          h="4rem"
          fontSize={"1.4rem"}
          onClick={() => navigate("/projects")}
        >
          <Text color={"white"} as='b'>Projects</Text>
      </Button>      
    </VStack>
  );
};

export default Home;
