import React from "react";
import { useDisclosure, VStack, Text, HStack, Stack, Input, Button, Spinner, useToast } from "@chakra-ui/react";
import LabelSelect from "components/common/LabelSelect";
import YoutubeProfile from "components/home/YoutubeProfile";
import CodeGenerator from "components/home/CodeGenerator";
import CircuitDiagram from "components/home/CircuitDiagram";
import { YouTube } from "interfaces/youtube";
import { useState, useMemo, useEffect } from "react";
import { useAuth } from "context/ProtectedRoutes";
import { isYoutubeChannelUrl } from "utils/function";
import { useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import api from "services/api";
import moment from "moment-timezone";
import InputNumber from "components/common/InputNumber";
import ActionButton from "components/common/ActionButton";
import InputCheckbox from "components/common/InputCheckbox";
import { RESPONSIVE_STACK_DIRECTION } from "constants/ui";
import ProjectDetailLayout from "components/layouts/ProjectDetailLayout";

const YoutubeCounter = () => {
  // const navigate = useNavigate();
  const toast = useToast();
  const { user, updateUser } = useAuth();
  const { projectID } = useParams();
  const [projectName, setProjectName] = useState("");
  const [projectImgUrl, setProjectImgUrl] = useState("");
  const [projectDiagram, setProjectDiagram] = useState("");
  const { isOpen: isLoading, onClose, onOpen } = useDisclosure();
  const { isOpen: isCodeGenOpen, onOpen: onCodeGenOpen, onClose: onCodeGenClose } = useDisclosure();
  const { isOpen: isPlanOpen, onOpen: onPlanOpen, onClose: onPlanClose } = useDisclosure();
  const { isOpen: isSaveOpen, onOpen: onSaveOpen, onClose: onSaveClose } = useDisclosure();
  const { isOpen: isOpenAPIProfile, onClose: onCloseAPIProfile, onOpen: onOpenAPIProfile } = useDisclosure();
  const [youtube, setYoutube] = useState<YouTube>();
  const [url, setUrl] = useState("");
  // const [channelId, setChannelId] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [matrixModule, setMatrixModule] = useState("8");
  const [dispTime, setDispTime] = useState("true");
  const [dispDay, setDispDay] = useState("true");
  const [dispDate, setDispDate] = useState("true");
  const [dispChannel, setDispChannel] = useState("true");
  const [dispSubs, setDispSubs] = useState("true");
  const [dispViews, setDispViews] = useState("true");
  const [transitionTime, setTransitionTime] = useState("5");
  const [transitionType, setTransitionType] = useState(0);
  const [wifiSSID, setWifiSSID] = useState("");
  const [wifiPassword, setWifiPassword] = useState("");
  const [boardList, setBoardList] = useState<string[]>([]);
  const [timezoneValid, setTimezoneValid] = useState(true);
  const [dispModuleValid, setDispModuleValid] = useState(true);
  // const [isSaveOpen, setIsSaveOpen] = useState(false);

  const timeZones = moment.tz.names().map(el => ({ label: el, value: el }));

  const handleUpdateYoutubeInfo = (ytb?: YouTube) => {
    setYoutube(ytb);
    if (ytb) onOpenAPIProfile();
    else onCloseAPIProfile();
  };

  interface TransitionType {
    id: number;
    name: string;
  }

  const [transitionList] = useState<TransitionType[]>([
    {
      id: 0,
      name: "Fade",
    },
    {
      id: 1,
      name: "Scroll",
    },
  ]);

  const saveSettings = () => {
    const data = {
      userID: user?.id,
      ytChannel: user?.youtubeChannel,
      channelId: '',
      timezone: parseInt(selectedTimezone),
      dispTime: dispTime,
      dispDate: dispDate,
      dispDay: dispDay,
      dispChannel: dispChannel,
      dispSubs: dispSubs,
      dispViews: dispViews,
      transitionTime: parseInt(transitionTime),
      transitionType: transitionType,
    };

    onSaveOpen();

    api
      .post("/savesettings", data)
      .then(res => {
        console.log(res);
        onSaveClose();
        toast({
          title: "Settings Saved!!!",
          description: "Your settings have been saved!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
          containerStyle: { fontSize: "1.4rem" },
        });
      })
      .catch(err => {
        console.log(JSON.stringify(err));
      })
      .finally(() => {});
  };

  const handlePreviewChannel = (link?: string) => {
    setYoutube(undefined);
    onOpen();
    api
      .get("/youtube/channel", {
        params: {
          url: link ? link : url,
        },
      })
      .then(data => {
        if (data) {
          if (user) updateUser?.({ ...user, youtubeChannel: link ? link : url });
          handleUpdateYoutubeInfo(data as YouTube);
        }
      })
      .finally(() => onClose());
  };

  const isDisabled = useMemo(() => {
    if (!url || (url && !isYoutubeChannelUrl(url))) return true;
    return false;
  }, [url]);

  useEffect(() => {
    api.get("/boards", { params: { projectID: projectID } }).then(res => {
      if (res.data && res.data.length > 0) {
        setBoardList(res.data);
      }
    });
    api.get("/getprojectheader", { params: { projectID: projectID } }).then(res => {
      if (res.projectName && res.projectImgUrl) {
        setProjectName(res.projectName);
        setProjectImgUrl(res.projectImgUrl);
        if (res.projectDiagram) {
          setProjectDiagram(res.projectDiagram);
        }
      }
    });
  }, [projectID]);

  return (
    <ProjectDetailLayout projectName={projectName} projectImgUrl={projectImgUrl}>
      {projectName.length > 0 && projectImgUrl.length > 0 && boardList.length > 0 ? (
        <VStack alignItems="start" w="100%" spacing="4rem" p="2rem">
          {/* Microcontroller */}
          <Stack direction={["column", "column", "row"]} w="100%" spacing="2rem" alignItems="start">
            <Text fontSize="4xl" minW="200px" pt="0.9rem">
              Microcontroller
            </Text>
            <LabelSelect
              options={boardList.map(el => ({
                label: el,
                value: el.replaceAll(" ", "_").toLocaleLowerCase(),
              }))}
              maxHeight="50%"
              w={{ base: "20rem", md: "40rem" }}
            />
          </Stack>

          {/* Display */}
          <Stack direction={["column", "column", "row"]} alignItems="start" spacing="1rem">
            <Text fontSize="4xl" minW="200px" pt="0.6rem">
              Display
            </Text>
            <HStack>
              <Text fontSize="3xl" pt="0.6rem">
                MAX7219 Dot Matrix Module
              </Text>
              <Text fontSize="3xl" pt="0.6rem">
                X
              </Text>
              <VStack alignItems={"flex-start"}>
                <InputNumber
                  value={matrixModule}
                  onChange={(s, n) => {
                    setMatrixModule(s);
                  }}
                />
              </VStack>
            </HStack>
          </Stack>

          {/* SSID & WiFi Password */}
          <Stack direction={RESPONSIVE_STACK_DIRECTION} alignItems="start" spacing="1rem">
            <Text fontSize="4xl" minW="200px" pt="0.6rem">
              Network
            </Text>
            <VStack alignItems={"flex-start"}>
              <Input
                placeholder="SSID"
                size="lg"
                borderColor="rgb(98 98 98)"
                borderWidth="2px"
                borderRadius="10px"
                h="4.5rem"
                w={{ base: "20rem", md: "40rem" }}
                fontSize="1.5rem"
                onChange={e => {
                  setWifiSSID(e.target.value);
                }}
              />
            </VStack>
          </Stack>
          <Stack direction={RESPONSIVE_STACK_DIRECTION} alignItems="start" spacing="1rem">
            <Text fontSize="4xl" minW="200px" pt="0.6rem">
              Network Password
            </Text>
            <VStack alignItems={"flex-start"}>
              <Input
                placeholder="PASSWORD"
                borderColor="rgb(98 98 98)"
                borderWidth="2px"
                borderRadius="10px"
                h="4.5rem"
                w={{ base: "20rem", md: "40rem" }}
                fontSize="1.5rem"
                onChange={e => {
                  setWifiPassword(e.target.value);
                }}
              />
            </VStack>
          </Stack>

          {/* YouTube Channel */}
          <Stack direction={RESPONSIVE_STACK_DIRECTION} alignItems="start" spacing="1rem">
            <Text fontSize="4xl" minW="200px" pt="0.5rem">
              YouTube Channel
            </Text>
            <Stack direction={RESPONSIVE_STACK_DIRECTION} gap={[10]}>
              <VStack alignItems={"flex-start"}>
                <Stack direction={["column", "column", "row"]} alignItems="start" spacing="1rem">
                  <VStack alignItems={"flex-start"}>
                    <Input
                      placeholder="YouTube Channel"
                      size="lg"
                      borderColor="rgb(98 98 98)"
                      borderWidth="2px"
                      borderRadius="10px"
                      h="4.5rem"
                      w={280}
                      fontSize="1.5rem"
                      onChange={e => setUrl(e.target.value)}
                    />
                    {!youtube && <Text color="red">Please select YouTube channel</Text>}
                  </VStack>
                  <Button
                    size="lg"
                    borderColor="black"
                    bg="black"
                    borderWidth="2px"
                    textColor="cyan.500"
                    borderRadius="10px"
                    h="4.5rem"
                    w={{ base: "10rem", md: "13rem" }}
                    fontSize="4xl"
                    onClick={() => handlePreviewChannel()}
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                  >
                    Check it!
                  </Button>
                </Stack>
              </VStack>
              {isOpenAPIProfile && (
                <Stack marginTop={"12"}>
                  <YoutubeProfile youtube={youtube} />
                </Stack>
              )}
            </Stack>
          </Stack>

          {/* YouTube Channel ID */}
          {/*
          <Stack direction={RESPONSIVE_STACK_DIRECTION} alignItems="start" spacing="1rem">
            <Text fontSize="4xl" minW="200px" pt="0.5rem">
              YouTube Channel ID
            </Text>
            <Stack direction={RESPONSIVE_STACK_DIRECTION} gap={[10]}>
              <VStack alignItems={"flex-start"}>
                <Stack direction={["column", "column", "row"]} alignItems="start" spacing="1rem">
                  <VStack alignItems={"flex-start"}>
                    <Input
                      placeholder="YouTube Channel ID"
                      size="lg"
                      borderColor="rgb(98 98 98)"
                      borderWidth="2px"
                      borderRadius="10px"
                      h="4.5rem"
                      w={280}
                      fontSize="1.5rem"
                      onChange={e => setChannelId(e.target.value)}
                    />
                    {!channelId && <Text color="red">Please input YouTube ID</Text>}
                  </VStack>
                </Stack>
              </VStack>
            </Stack>
          </Stack>
          */}

          {/* Timezone */}
          <Stack direction={RESPONSIVE_STACK_DIRECTION} alignItems="start" spacing="1rem">
            <Text fontSize="4xl" minW="200px" pt="0.9rem">
              Timezone
            </Text>
            <VStack alignItems={"flex-start"}>
              <LabelSelect
                options={timeZones}
                // value={selectedTimezone}
                onChange={e => {
                  const offsetTimeZone = e.target.value ? moment.tz(e.target.value).utcOffset() * 60 : "";
                  setSelectedTimezone(String(offsetTimeZone));
                  // setSelectedBoard((prevState) => ({
                  //   ...prevState,
                  //   timezone: e.target.value
                  // }));
                }}
                maxHeight="50%"
                placeholder="Select Timezone"
                w={{ base: "20rem", md: "40rem" }}
              />
              {!timezoneValid && <Text color="red">Please select timezone</Text>}
            </VStack>
          </Stack>

          {/* Display Modules */}
          <Stack direction={RESPONSIVE_STACK_DIRECTION} alignItems="start" spacing="1rem">
            <Text fontSize="4xl" minW="200px" pt="0.9rem">
              Display Modules
            </Text>
            <Stack alignItems={"flex-start"}>
              <Stack direction={RESPONSIVE_STACK_DIRECTION} alignItems="start" spacing="1rem">
                <InputCheckbox
                  text="Time"
                  handleOnCheckboxChange={(value, checked) => {
                    setDispTime(checked.toString());
                  }}
                />
                <InputCheckbox
                  text="Date"
                  handleOnCheckboxChange={(value, checked) => {
                    setDispDate(checked.toString());
                  }}
                />
                <InputCheckbox
                  text="Day"
                  handleOnCheckboxChange={(value, checked) => {
                    setDispDay(checked.toString());
                  }}
                />
                <InputCheckbox
                  text="Channel"
                  handleOnCheckboxChange={(value, checked) => {
                    setDispChannel(checked.toString());
                  }}
                />
                <InputCheckbox
                  text="Subs"
                  handleOnCheckboxChange={(value, checked) => {
                    setDispSubs(checked.toString());
                  }}
                />
                <InputCheckbox
                  text="Views"
                  handleOnCheckboxChange={(value, checked) => {
                    setDispViews(checked.toString());
                  }}
                />
              </Stack>
              {!dispModuleValid && <Text color="red">Please select at least one module</Text>}
            </Stack>
          </Stack>

          {/* Transition time */}
          <Stack direction={RESPONSIVE_STACK_DIRECTION} alignItems="start" spacing="1rem">
            <Text fontSize="4xl" minW="200px" pt="0.5rem">
              Transition time
            </Text>
            <VStack alignItems={"flex-start"}>
              <InputNumber
                value={transitionTime}
                onChange={(s, n) => {
                  setTransitionTime(s);
                }}
              />
            </VStack>
            <Text fontSize="4xl" minW="200px" align="left" pt="0.5rem">
              Seconds
            </Text>
          </Stack>

          {/* Transition Type */}
          <Stack direction={RESPONSIVE_STACK_DIRECTION} alignItems="start" spacing="1rem">
            <Text fontSize="4xl" minW="200px" pt="0.9rem">
              Transition Type
            </Text>
            <VStack alignItems={"flex-start"}>
              <LabelSelect
                options={transitionList.map(el => ({
                  label: el.name,
                  value: el.id,
                }))}
                // value={selectedMCU}
                onChange={e => {
                  setTransitionType(parseInt(e.target.value));
                }}
                maxHeight="50%"
                w={{ base: "20rem", md: "40rem" }}
              />
            </VStack>
          </Stack>

          {/* Code! Plans! 3D Enclosure! */}
          <VStack alignItems="start" spacing="6">
            {!isSaveOpen && (
              <ActionButton
                text="Save Settings!"
                onClick={() => {
                  const allDispInvalid =
                    dispTime === "false" &&
                    dispDay === "false" &&
                    dispDate === "false" &&
                    dispChannel === "false" &&
                    dispSubs === "false" &&
                    dispViews === "false";
                  if (selectedTimezone && youtube && allDispInvalid === false && parseInt(transitionTime) !== 0) {
                    saveSettings();
                  }

                  setTimezoneValid(selectedTimezone === "" ? false : true);
                  setDispModuleValid(allDispInvalid ? false : true);
                }}
              />
            )}
            {isSaveOpen && <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />}
            <ActionButton
              text="Code!"
              onClick={() => {
                const allDispInvalid =
                  dispTime === "false" &&
                  dispDay === "false" &&
                  dispDate === "false" &&
                  dispChannel === "false" &&
                  dispSubs === "false" &&
                  dispViews === "false";
                if (selectedTimezone && youtube && allDispInvalid === false && parseInt(transitionTime) !== 0) {
                  onCodeGenOpen();
                }

                setTimezoneValid(selectedTimezone === "" ? false : true);
                setDispModuleValid(allDispInvalid ? false : true);
              }}
            />
            <ActionButton
              text="Plan!"
              onClick={() => {
                if (projectDiagram.length > 0) {
                  onPlanOpen();
                }
              }}
            />
            <ActionButton
              text="3D Enclosure!"
              onClick={() => {
                // TODO:
              }}
            />
          </VStack>
          <CodeGenerator
            isOpen={isCodeGenOpen}
            onClose={onCodeGenClose}
            projectID={projectID}
            projectName={projectName}
            params={[
              matrixModule,
              selectedTimezone,
              dispTime,
              dispDay,
              dispDate,
              dispChannel,
              dispSubs,
              dispViews,
              transitionTime,
              wifiSSID,
              wifiPassword,
              user?.displayName ?? "",
            ]}
          />
          <CircuitDiagram isOpen={isPlanOpen} onClose={onPlanClose} url={projectDiagram} />
        </VStack>
      ) : (
        <></>
      )}
    </ProjectDetailLayout>
  );
};

export default YoutubeCounter;

// return (
//   <VStack w="100%" spacing="2rem">
//     <APIFeed setYoutube={handleUpdateYoutubeInfo} />
//     {isOpenAPIProfile && <APIProfile onSubmit={onOpen} youtube={youtube} />}
//     <CodeGenerator isOpen={isOpen} onClose={onClose} />
//   </VStack>
// );
