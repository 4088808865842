import {
  useDisclosure,
  VStack,
  HStack,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Image,
  Box,
  Link,
  StackDivider,
  Spinner,
  Stack,
  Flex,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { useAuth } from "context/ProtectedRoutes";
import { useNavigate } from "react-router-dom";
import api from "services/api";

const Projects = () => {
  const { onClose, onOpen } = useDisclosure();
  const { onOpen: onOpenMsg } = useDisclosure();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [dataOK, setDataOK] = useState(false);
  const [myData, setMyData] = useState({
    projects: [
      {
        id: "",
        name: "",
        description: "",
        images: [],
        path: "",
      },
    ],
  });

  const searchString = "";

  const handleRequestSearch = () => {
    onOpen();
    api
      .post("/searchprojects", searchString)
      .then(res => {
        setMyData(res);
        setDataOK(true);
      })
      .catch(err => {
        console.log(JSON.stringify(err));
        onOpenMsg();
      })
      .finally(() => onClose());
  };

  useEffect(() => {
    if (user) {
      handleRequestSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Box mt={12} w="100%" minH="var(--min-height)">
      <Text fontSize={["sm", "2xl", "4xl"]} as="b" minW={"200"}>
        HOME / PROJECTS
      </Text>
      <VStack mt={12} w="100%" justify="center" spacing={"3rem"}>
        <InputGroup w="50%" alignSelf={["center", "center", "flex-end"]}>
          <Input borderRadius={4} placeholder="Search Projects" size="lg" h="4.5rem" fontSize="1.5rem" />
          <InputRightElement p="2rem" children={<Search2Icon h="2rem" w="2rem" color={"gray"} />} />
        </InputGroup>

        {dataOK ? (
          <Grid gridTemplateColumns={["1fr", "1fr", "1fr 1fr 1fr 1fr"]} gap={12} w="100%" justifyContent={"center"}>
            {Array(Object.keys(myData?.projects).length)
              .fill("")
              .map((n, i) => {
                return (
                  <GridItem key={i} colSpan={[3, 3, 1]}>
                    <Box bg="var(--main-gray) " minH={["unset", "unset", "400px"]} maxH={"500px"} borderRadius={"4px"} padding={"2rem"}>
                      <HStack w="100%" h="100%">
                        <Stack w="100%" h="100%" spacing={"1rem"} direction={["column"]}>
                          <Box w={[150, 150, 200]} h={[150, 150, 200]} alignSelf="center">
                            {myData.projects[i].images[0] && (
                              <Image objectFit={"scale-down"} w={"100%"} h={"100%"} borderRadius={"1rem"} src={myData.projects[i].images[0]} />
                            )}
                          </Box>
                          <Link
                            as="b"
                            fontSize={"4xl"}
                            onClick={() => {
                              navigate("/" + myData.projects[i].path + "/" + myData.projects[i].id);
                            }}
                          >
                            {myData.projects[i].name}
                          </Link>
                          <Text textOverflow={"ellipsis"} fontSize={["3xl", "2xl", "3xl"]}>
                            {myData.projects[i].description}
                          </Text>
                        </Stack>
                      </HStack>
                    </Box>
                  </GridItem>
                );
              })}
          </Grid>
        ) : (
          <Flex h={"50vh"} alignItems={"center"}>
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
          </Flex>
        )}
      </VStack>
    </Box>
  );
};

export default Projects;
