import { useLocalStorage } from "hooks/useLocalStorage";
import { AuthInfo, UserInfo } from "interfaces/user";
import React, { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import api from "services/api";

const AuthContext = createContext<AuthInfo>({});

export const AuthProvider: React.FC<any> = ({ children }) => {
  const { storedValue: user, setValue: setUser } = useLocalStorage<
    UserInfo | undefined
  >("userInfo", {});
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data: UserInfo) => {
    setUser(data);
    navigate("/");
  };

  // call this function to sign out logged in user
  const logout = () => {
    api.post("/logout");
    setUser(null);
    navigate("/", { replace: true });
  };

  const updateUser = (info: UserInfo) => {
    setUser(info);
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      updateUser,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
