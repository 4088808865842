import axios from "axios";
import { REACT_APP_BASE_CMS_URL, REACT_APP_CMS_CONTENT_API_KEY } from "constants/api";
import { CMSApiResponse } from 'interfaces/cms';

const request = {
  async get(url: string) {
    return (await axios.get(REACT_APP_BASE_CMS_URL + url + `&key=${REACT_APP_CMS_CONTENT_API_KEY}`)).data
  }
}

const CMSApi = {
  async getFAQ() {
    const data = await request.get("/posts?filter=tag:faq") as CMSApiResponse
    return data;
  },
  async getProjects() {
    const data = await request.get("/posts?filter=tag:project") as CMSApiResponse
    return data;
  },
  async getProjectDetail(id: string) {
    const data = await request.get(`/posts/${id}?filter=tag:project`) as CMSApiResponse
    return data;
  },
  async getAbout() {
    const data = await request.get(`/posts/?filter=tag:about`) as CMSApiResponse 
    return data;
  }
}

export default CMSApi;