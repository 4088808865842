import React from 'react';
import { Box, Flex, HStack, Image, Link, Stack, VStack, Text, useDisclosure, Spinner } from "@chakra-ui/react";
import LabelSelect from "components/common/LabelSelect";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "services/api";
import ActionButton from "components/common/ActionButton";
import CodeGenerator from "components/home/CodeGenerator";
import CircuitDiagram from "components/home/CircuitDiagram";
import ProjectDetailLayout from "components/layouts/ProjectDetailLayout";
import { RESPONSIVE_STACK_DIRECTION } from "constants/ui";

const LightPuckTx = () => {
  const navigate = useNavigate();
  // const { user, updateUser } = useAuth();
  const [projectName, setProjectName] = useState("");
  const [projectImgUrl, setProjectImgUrl] = useState("");
  const [projectDiagram, setProjectDiagram] = useState("");
  const { projectID } = useParams();
  const { isOpen: isCodeGenOpen, onOpen: onCodeGenOpen, onClose: onCodeGenClose } = useDisclosure();
  const { isOpen: isPlanOpen, onOpen: onPlanOpen, onClose: onPlanClose } = useDisclosure();
  const [boardList, setBoardList] = useState<string[]>([]);

  interface ControlType {
    name: string;
    id: number;
  }
  const [controlTypeList] = useState<ControlType[]>([
    {
      name: "1",
      id: 1,
    },
    {
      name: "2",
      id: 2,
    },
    {
      name: "3",
      id: 3,
    },
  ]);

  useEffect(() => {
    api.get("/boards", { params: { projectID: projectID } }).then(res => {
      if (res.data && res.data.length > 0) {
        setBoardList(res.data);
      }
    });
    api.get("/getprojectheader", { params: { projectID: projectID } }).then(res => {
      if (res.projectName && res.projectImgUrl) {
        setProjectName(res.projectName);
        setProjectImgUrl(res.projectImgUrl);
        if (res.projectDiagram) {
          setProjectDiagram(res.projectDiagram);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectID]);

  return (
    <ProjectDetailLayout projectName={projectName} projectImgUrl={projectImgUrl}>
      {projectName.length > 0 && projectImgUrl.length > 0 && boardList.length > 0 ? (
        <VStack alignItems="start" w="100%" spacing="4rem" p="2rem">
          {/* Microcontroller */}
          <Stack direction={RESPONSIVE_STACK_DIRECTION} alignItems="start" w="100%" spacing="2rem">
            <Text fontSize="4xl" minW="200px" pt="0.9rem">
              Microcontroller
            </Text>
            <LabelSelect
              options={boardList.map(el => ({
                label: el,
                value: el.replaceAll(" ", "_").toLocaleLowerCase(),
              }))}
              maxHeight="50%"
              w={{ base: "20rem", md: "40rem" }}
            />
          </Stack>

          {/* Control type */}
          <Stack direction={RESPONSIVE_STACK_DIRECTION} alignItems="start" w="100%" spacing="2rem">
              <Text fontSize="4xl" minW="200px" pt="0.9rem">
                Select Button
              </Text>
              <LabelSelect
                options={controlTypeList.map(el => ({
                  label: el.name,
                  value: el.id,
                }))}
                maxHeight="50%"
                placeholder="Button Type"
                w={{ base: "20rem", md: "40rem" }}
              />
          </Stack>

          {/* Code! Plans! 3D Enclosure! */}
          <VStack alignItems="start" spacing="6">
            <ActionButton
              text="Code!"
              onClick={() => {
                onCodeGenOpen();
              }}
            />
            <ActionButton
              text="Plan!"
              onClick={() => {
                if (projectDiagram.length > 0) {
                  onPlanOpen();
                }
              }}
            />
            <ActionButton
              text="3D Enclosure!"
              onClick={() => {
                // TODO:
              }}
            />
          </VStack>

          <CodeGenerator isOpen={isCodeGenOpen} onClose={onCodeGenClose} projectID={projectID} projectName={projectName} params={[""]} />
          <CircuitDiagram isOpen={isPlanOpen} onClose={onPlanClose} url={projectDiagram} />
        </VStack>
      ) : (
        <></>
      )}
    </ProjectDetailLayout>
  );
};

export default LightPuckTx;
