import {
  Box,
  BoxProps,
  Divider,
  HStack,
  Input,
  InputGroup,
  InputProps,
  StackProps,
  Text,
  TextProps,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { isYoutubeChannelUrl, validateEmail } from "utils/function";

const AnimationInput = ({
  name,
  leftIcon,
  boxProps,
  iconProps,
  invalidMsg,
  isYoutube,
  isShowDividerError,
  isEmail,
  invalidMsgStyle,
  msgOnFocus,
  onChange,
  ...rest
}: {
  name?: string;
  leftIcon?: React.ReactNode;
  boxProps?: StackProps;
  iconProps?: BoxProps;
  invalidMsg?: string;
  isYoutube?: boolean;
  isEmail?: boolean;
  isShowDividerError?: boolean;
  invalidMsgStyle?: TextProps;
  msgOnFocus?: string;
} & InputProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: false });
  const {
    isOpen: isFocus,
    onClose: onCloseFocus,
    onOpen: onOpenFocus,
  } = useDisclosure({ defaultIsOpen: false });
  const labelRef = useRef<HTMLParagraphElement>(null);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isEmail) {
      if (value && !validateEmail(value)) {
        onOpen();
        return;
      }
      onClose();
    }
    if (isYoutube) {
      if (!value || (value && !isYoutubeChannelUrl(value))) {
        labelRef.current?.parentElement?.setAttribute("id", "invalid");
        labelRef.current?.setAttribute("style", "color: red !important;");
        onOpen();
      } else {
        labelRef.current?.parentElement?.removeAttribute("id");
        labelRef.current?.removeAttribute("style");
        onClose();
      }
    }
    onChange?.(e);
  };

  return (
    <VStack w="80%" spacing={"1.5rem"} alignItems={"flex-start"} {...boxProps}>
      <HStack alignItems={"flex-end"} w="100%">
        {leftIcon && (
          <Box w="3rem" {...iconProps}>
            {leftIcon}
          </Box>
        )}
        <InputGroup className="animation-input" flex={1}>
          <Input
            size="lg"
            fontSize={"1.6rem"}
            pl="0"
            borderTop="none"
            borderLeft="none"
            borderRight="none"
            borderRadius={0}
            required
            autoComplete="new-password"
            {...rest}
            onChange={(e) =>
              isYoutube || isEmail ? handleInputChange(e) : onChange?.(e)
            }
            onFocus={() => {
              if (msgOnFocus) onOpenFocus();
            }}
            onBlur={() => onCloseFocus()}
          />
          <Text as="label" className="label-name">
            <Text as="span" className="content-name" ref={labelRef}>
              {name}
            </Text>
          </Text>
        </InputGroup>
      </HStack>
      {isOpen && (
        <>
          <Text color="red" pl="3.5rem" {...invalidMsgStyle}>
            {invalidMsg}
          </Text>
          {isShowDividerError && <Divider />}
        </>
      )}
      {isFocus && (
        <>
          <Text color="var(--secondary-color)" fontSize={"1.2rem"}>
            {msgOnFocus}
          </Text>
        </>
      )}
    </VStack>
  );
};

export default AnimationInput;
