import React, { useEffect, useState } from "react";
import { Container, Flex, Text } from "@chakra-ui/react";
import CMSApi from "modules/cms/api";
import { CMSPost } from "interfaces/cms";

const FAQ = () => {
	const [faqs, setFaqs] = useState<CMSPost[]>([]);

	useEffect(() => {
		(async () => {
			const data = await CMSApi.getFAQ();
			setFaqs(data.posts);
		})();
	}, []);

	return (
		<Flex p={[12, 20]} style={{ flexDirection: "column", height: "80%" }}>
			<Flex justifyContent={"center"} alignItems={"center"} gap={4}>
				<div style={{ fontSize: 24, fontWeight: "bold" }}>
					<Text color={"#66a49f"} fontSize={["6xl", "8xl"]}>
						F.A.Q
					</Text>
				</div>
			</Flex>
			<div style={{ fontSize: "20px" }}>
				{faqs.map((item) => (
					<Container marginTop={4}>
						<Text color="#66a49f" fontSize={["3xl", "4xl"]} fontWeight="bold">
							{item.title}
						</Text>
						<Text>{item.excerpt}</Text>
					</Container>
				))}
			</div>
		</Flex>
	);
};

export default FAQ;
